import { useStaticQuery, graphql } from 'gatsby';

export const getMenuItems = () => {
  const { allStartASiteMenuItem } = useStaticQuery(
    graphql`
      query MenuItemsQuery {
        allStartASiteMenuItem(sort: { fields: index, order: ASC }) {
          edges {
            node {
              id
              url
              text
              target
              index
              urlId
            }
          }
        }
      }
    `,
  );
  return allStartASiteMenuItem.edges.map(edge => edge.node);
};

export interface MenuItem {
  id: string;
  text: string;
  url: string;
  target: string;
}
