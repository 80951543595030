import React from 'react';
import Layout from './components/Layout';
import { convertHtmlToReact } from './common/convert-html-to-react';

export interface GatsbyPageArg<T> {
  location: Location;
  navigate: Function;
  pageContext: T;
  path: string;
}

export interface PageContext {
  body: string[];
  title: string;
  metadescription: string;
}

export default (args: GatsbyPageArg<PageContext>) => {
  const pageContext = args.pageContext;

  return (
    <>
      <Layout
        title={pageContext.title}
        description={pageContext.metadescription}
      >
        <section className="section">
          <div className="container content">
            <h1>{pageContext.title}</h1>
            {pageContext.body.map((html, index) => {
              return convertHtmlToReact(html, { index });
            })}
          </div>
        </section>
      </Layout>
    </>
  );
};
