import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

const NonStretchedImage = props => {
  let normalizedProps = props;
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: '0 auto', // Used to center the image
      },
    };
  }

  return <Img {...normalizedProps} />;
};

interface LogoProps {
  style?: React.CSSProperties;
  linkUrl?: string;
}

const Logo: React.FunctionComponent<LogoProps> = ({ style, linkUrl }) => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
    }
  `);

  const wrapWithLink = children => {
    if (!linkUrl) {
      return children;
    }
    return (
      <Link
        to={linkUrl}
        className="navbar-item"
        title="Logo"
        style={{ width: '100%' }}
      >
        {children}
      </Link>
    );
  };

  const logoElement = NonStretchedImage({
    ...data.file.childImageSharp,
    alt: 'UKCPC',
    style: { width: '100%' },
  });

  return <div style={style}>{wrapWithLink(logoElement)}</div>;
};

export default Logo;
