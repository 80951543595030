import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../Footer';
import Navbar from '../Navbar';
import '../all.sass';
import { getMenuItems } from '../../hooks/get-menu-items';
import { getConfigurationValue } from '../../hooks/get-configuration-value';

type LayoutProps = {
  title: string;
  description: string;
};

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  title,
  description = getConfigurationValue('META_DESCRIPTION') as string,
}) => {
  const siteName = getConfigurationValue('SITE_NAME');
  const menuItems = getMenuItems();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{siteName + ' - ' + title}</title>
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content={getConfigurationValue('META_KEYWORDS') as string}
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={siteName + ' - ' + title} />
        <meta property="og:url" content="/" />
      </Helmet>
      <Navbar menuItems={menuItems} />
      <div>{children}</div>
      <Footer menuItems={menuItems} />
    </div>
  );
};
export default Layout;
