import { graphql, useStaticQuery } from 'gatsby';
const convertValue = (
  value: string,
  type: string,
): number | boolean | string => {
  switch (type.toLowerCase()) {
    case 'int':
      return parseInt(value);
    case 'boolean':
      return value.toLowerCase() === 'true' ? true : false;
    default:
      return value;
  }
};

export const getConfigurationValue = (variableName: string) => {
  const query = useStaticQuery(
    graphql`
      query MyQuery {
        allStartASiteConfigurationValue {
          nodes {
            value
            variable
            type
          }
        }
      }
    `,
  );
  const node = query.allStartASiteConfigurationValue.nodes.find(
    node => node.variable === variableName,
  );

  return convertValue(node.value, node.type);
};
