import React from 'react';
import { Link } from 'gatsby';
import Logo from '../Logo/index';
import { MenuItem } from '../../hooks/get-menu-items';

type NavbarProps = {
  menuItems: MenuItem[];
};
type NavbarState = {
  active: boolean;
  navBarActiveClass: string;
};

class Navbar extends React.Component<NavbarProps, NavbarState> {
  constructor(props: NavbarProps) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: '',
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            });
      },
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand is-one-quarter">
            <Logo style={{ width: '10rem' }} linkUrl="/" />
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              {this.props.menuItems.map(element =>
                element.url.match(/^http?s:/) ? (
                  <a
                    key={element.id}
                    className="navbar-item"
                    href={element.url}
                    target={element.target}
                  >
                    {element.text}
                  </a>
                ) : (
                  <Link
                    key={element.id}
                    className="navbar-item"
                    to={element.url}
                    target={element.target}
                  >
                    {element.text}
                  </Link>
                ),
              )}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
