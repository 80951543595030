import React from 'react';
import convertFromString from 'react-from-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { camelCase } from 'change-case';

const convertCssToCssProps = (css: string): React.CSSProperties => {
  if (!css) {
    return {};
  }
  let styles = {};
  css
    .split(';')
    .map(x => x.trim())
    .filter(x => x.length > 0)
    .map(style => {
      const parsed = style.split(':').map(x => x.trim());
      return { [camelCase(parsed[0])]: parsed[1] };
    })
    .forEach(element => {
      styles = { ...styles, ...element };
    });
  return styles;
};

export const convertHtmlToReact = (html: string, options: { index?: number }) =>
  convertFromString(`<div>${html}</div>`, {
    ...options,
    actions: [
      {
        condition: node => node.nodeName.toLowerCase() === 'img',
        post: (node, key) => {
          const el = node as Element;
          return (
            <LazyLoadImage
              key={key}
              style={convertCssToCssProps(
                el.attributes.getNamedItem('style')?.value,
              )}
              alt={el.attributes.getNamedItem('alt')?.value}
              height={el.attributes.getNamedItem('height')?.value}
              src={el.attributes.getNamedItem('src')?.value}
              srcSet={el.attributes.getNamedItem('srcSet')?.value}
              width={el.attributes.getNamedItem('width')?.value}
            />
          );
        },
      },
    ],
  });
