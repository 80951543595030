import React from 'react';
import { Link } from 'gatsby';
import Logo from '../Logo/index';
import facebook from '../../images/social/facebook.svg';
import instagram from '../../images/social/instagram.svg';
import twitter from '../../images/social/twitter.svg';
import vimeo from '../../images/social/vimeo.svg';

import { MenuItem } from '../../hooks/get-menu-items';

class Footer extends React.Component<{ menuItems: MenuItem[] }> {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <Logo style={{ margin: '0 auto', width: '6rem' }} />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    {this.props.menuItems.map(element => (
                      <li key={element.id}>
                        {element.url.match(/^http?s:/) ? (
                          <a
                            key={element.id}
                            className="navbar-item"
                            href={element.url}
                            target={element.target}
                          >
                            {element.text}
                          </a>
                        ) : (
                          <Link
                            key={element.id}
                            className="navbar-item"
                            to={element.url}
                            target={element.target}
                          >
                            {element.text}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </section>
              </div>
              <div className="column is-4"></div>
              <div className="column is-4 social">
                <a title="facebook" href="https://facebook.com">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="vimeo" href="https://vimeo.com">
                  <img
                    src={vimeo}
                    alt="Vimeo"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
